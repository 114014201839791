import {
  GetGroupsAPIResponse,
  PostGroupAPIResponse,
  GroupPayload,
  GetLocalizedGroupsAPIResponse,
  LocalizedGroupPayload,
  PostLocalizedGroupsAPIResponse,
} from "app/types/package";
import axios from "./config";
import { AxiosResponse } from "axios";

export const getGroupListsApi = (
  storeId: number,
  query = ""
): Promise<AxiosResponse<GetGroupsAPIResponse>> =>
  axios.get<GetGroupsAPIResponse>(`/stores/${storeId}/groups${query}`);

type PostGroupApiRequest = {
  storeId: number;
  payload: GroupPayload;
};

export const postGroupApi = ({
  storeId,
  payload,
}: PostGroupApiRequest): Promise<AxiosResponse<PostGroupAPIResponse>> =>
  axios.post<PostGroupAPIResponse>(`/stores/${storeId}/groups`, payload);

type PutGroupApiRequest = {
  storeId: number;
  groupId: number;
  payload: GroupPayload;
};
export const putGroupApi = ({
  storeId,
  groupId,
  payload,
}: PutGroupApiRequest): Promise<AxiosResponse<PostGroupAPIResponse>> =>
  axios.put<PostGroupAPIResponse>(
    `/stores/${storeId}/groups/${groupId}`,
    payload
  );

// Localization API

type GetLocalizedGroupsApiRequest = {
  storeId: number;
  groupId: number;
  query: string;
};
export const getLocalizedGroupsApi = ({
  storeId,
  groupId,
  query = "",
}: GetLocalizedGroupsApiRequest): Promise<
  AxiosResponse<GetLocalizedGroupsAPIResponse>
> =>
  axios.get<GetLocalizedGroupsAPIResponse>(
    `/stores/${storeId}/groups/${groupId}/localizations${query}`
  );

export const postLocalizedGroupApi = ({
  storeId,
  groupId,
  payload,
}: {
  storeId: number;
  groupId: number;
  payload: LocalizedGroupPayload;
}): Promise<AxiosResponse<PostLocalizedGroupsAPIResponse>> =>
  axios.post<PostLocalizedGroupsAPIResponse>(
    `/stores/${storeId}/groups/${groupId}/localizations`,
    payload
  );
