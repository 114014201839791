import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { AppDispatch } from "../store";
import { alertMessage } from "../actions/common";
import { errorHandler } from "app/shared/Service/errorHandler";
import { createQuery } from "app/utils/constants/common";
import { RootState } from "../reducers/hooks";
import { PaginationInterfaceLF } from "app/types";
import { PricePlanInterface } from "app/types/pricePlans";
import { getAllBundleUnitsApi } from "app/services/packages";
interface PricePlansFilters {
  storeId: number;
  store: string;
  status?: string;
  purpose?: string;
  numberOfDays?: number;
  numberOfMeals?: number;
  numberOfSnacks?: number;
  search?: string;
}
interface InitialState {
  pricePlanList: PricePlanInterface[];
  pricePlanListPagination: PaginationInterfaceLF;
  pricePlanListRowsPerPage: number;
  isPricePlanListLoading: boolean;
  filters: PricePlansFilters | null;
}

const initialState: InitialState = {
  pricePlanList: [],
  pricePlanListRowsPerPage: 20,
  pricePlanListPagination: null,
  isPricePlanListLoading: false,
  filters: null,
};

export const pricePlans = createSlice({
  name: "pricePlans",
  initialState,
  reducers: {
    setPricePlanList: (
      state,
      action: PayloadAction<{
        list: PricePlanInterface[];
        pagination: PaginationInterfaceLF;
      }>
    ) => {
      state.pricePlanList = action.payload.list;
      state.pricePlanListPagination = action.payload.pagination;
    },

    setPricePlanListLoading: (state, action: PayloadAction<boolean>) => {
      state.isPricePlanListLoading = action.payload;
    },
    setPricePlanListRowPerPage: (state, action: PayloadAction<number>) => {
      state.pricePlanListRowsPerPage = action.payload;
    },
    setPricePlansFilters: (
      state,
      action: PayloadAction<PricePlansFilters | null>
    ) => {
      state.filters = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setPricePlanList,
  setPricePlanListLoading,
  setPricePlanListRowPerPage,
  setPricePlansFilters,
} = pricePlans.actions;

export default pricePlans.reducer;

export const getPricePlanListAction = (
  { continutationToken } = {
    continutationToken: null,
  },
  storeId: number
) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const { filters, pricePlanListRowsPerPage } = getState().pricePlans;
      let query = "";
      query =
        "?" +
        createQuery({
          filter: {
            ...(filters || {}),
            limit: pricePlanListRowsPerPage,
            continutationToken,
          },
        });
      dispatch(setPricePlanListLoading(true));
      const { data } = await getAllBundleUnitsApi(storeId, query);
      dispatch(setPricePlanListLoading(false));

      const pricePlans = data.data;
      const pagination = data.pagination;
      const currencies = data.included.currencies;
      const portions = data.included.portions;
      const bundles = data.included.bundles;
      const packages = data.included.packages;
      const groups = data.included.groups;

      // Update the price plan list
      const updatedList = pricePlans.map((plan) => {
        const currency = currencies.find(
          (item) => item.currencyId === plan.currencyId
        );
        const price = {
          amount: plan.price,
          code: currency?.localizedCode || currency?.code,
        };
        const discountedPrice = {
          amount: plan.discountedPrice,
          code: currency?.localizedCode || currency?.code,
        };
        const portion = portions.find(
          (item) => item.portionId === plan.portionId
        );
        const bundleItem = bundles.find(
          (item) => item.bundleId === plan.bundleId
        );
        const packageItem = packages.find(
          (item) => item.packageId === bundleItem.packageId
        );
        const groupItem = groups.find(
          (item) => item.groupId === packageItem.groupId
        );

        return {
          ...plan,
          price,
          discountedPrice,
          portion,
          bundle: bundleItem,
          package: packageItem,
          group: groupItem,
        };
      });

      dispatch(
        setPricePlanList({
          list: updatedList,
          pagination,
        })
      );
    } catch (error) {
      const message = errorHandler(error);
      dispatch(setPricePlanListLoading(false));
      dispatch(alertMessage(message, "error"));
    }
  };
};
