import {
  CreatePackagePayload,
  GetBundleByStoreAndPackageIdResponse,
  GetBundleUnitsByStoreAndPackageIdResponse,
  GetPackageByStoreApiResponse,
  PostBundleApiResponse,
  PostBundlePayload,
  PostBundleUnitApiResponse,
} from "app/types/package";
import axios from "./config";
import { AxiosResponse } from "axios";
import { BundleUnitPayload } from "app/types/common";

export const getPackagesListApi = (
  storeId: number | string,
  query: string
): Promise<AxiosResponse<GetPackageByStoreApiResponse>> =>
  axios.get<GetPackageByStoreApiResponse>(`/stores/${storeId}/packages${query}`);

export const postPackagesApi = (
  storeId: number,
  payload: CreatePackagePayload
) => {
  return axios.post(`/stores/${storeId}/packages`, payload);
};

export const putPackagesApi = (
  storeId: number,
  packageId: number,
  payload: CreatePackagePayload
) => {
  return axios.put(`/stores/${storeId}/packages/${packageId}`, payload);
};

// Bundles API

type BundlePostRequest = {
  packageId: number;
  storeId: number;
  payload: PostBundlePayload;
};

export const postBundlesApi = ({
  packageId,
  storeId,
  payload,
}: BundlePostRequest): Promise<AxiosResponse<PostBundleApiResponse>> => {
  return axios.post<PostBundleApiResponse>(
    `/stores/${storeId}/packages/${packageId}/bundles`,
    payload
  );
};

type BundlePutRequest = {
  packageId: number;
  bundleId: number;
  storeId: number;
  payload: PostBundlePayload;
};

export const putBundleApi = ({
  packageId,
  bundleId,
  storeId,
  payload,
}: BundlePutRequest): Promise<AxiosResponse<PostBundleApiResponse>> => {
  return axios.put<PostBundleApiResponse>(
    `/stores/${storeId}/packages/${packageId}/bundles/${bundleId}`,
    payload
  );
};

type GetBundleByStoreAndPackageIdRequest = {
  storeId: number;
  packageId: number;
  query: string;
};
export const getBundleByStoreAndPackageId = ({
  storeId,
  packageId,
  query,
}: GetBundleByStoreAndPackageIdRequest): Promise<
  AxiosResponse<GetBundleByStoreAndPackageIdResponse>
> => {
  return axios.get<GetBundleByStoreAndPackageIdResponse>(
    `/stores/${storeId}/packages/${packageId}/bundles${query}`
  );
};

// Bundle Units API

type GetBundleUnitsByStoreAndPackageIdRequest = {
  storeId: number;
  packageId: number;
  bundleId: number;
  query: string;
};
export const getBundleUnitsByStoreAndPackageId = ({
  storeId,
  packageId,
  bundleId,
  query,
}: GetBundleUnitsByStoreAndPackageIdRequest): Promise<
  AxiosResponse<GetBundleUnitsByStoreAndPackageIdResponse>
> => {
  return axios.get<GetBundleUnitsByStoreAndPackageIdResponse>(
    `/stores/${storeId}/packages/${packageId}/bundles/${bundleId}/units${query}`
  );
};

type BundleUnitPostRequest = {
  storeId: number;
  packageId: number;
  bundleId: number;
  payload: BundleUnitPayload;
};
export const postBundleUnitsApi = ({
  storeId,
  packageId,
  bundleId,
  payload,
}: BundleUnitPostRequest): Promise<
  AxiosResponse<PostBundleUnitApiResponse>
> => {
  return axios.post<PostBundleUnitApiResponse>(
    `/stores/${storeId}/packages/${packageId}/bundles/${bundleId}/units`,
    payload
  );
};

export const putBundleUnitApi = ({
  storeId,
  packageId,
  bundleId,
  unitId,
  payload,
}: BundleUnitPostRequest & { unitId: number }): Promise<
  AxiosResponse<PostBundleUnitApiResponse>
> => {
  return axios.put<PostBundleUnitApiResponse>(
    `/stores/${storeId}/packages/${packageId}/bundles/${bundleId}/units/${unitId}`,
    payload
  );
};

export const getAllBundleUnitsApi = (storeId: number | string, query: string) =>
  axios.get(`/stores/${storeId}/units${query}`);
