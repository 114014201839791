import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { AppDispatch } from "../store";
import { alertMessage } from "../actions/common";
import { errorHandler } from "app/shared/Service/errorHandler";
import { createQuery } from "app/utils/constants/common";
import { RootState } from "../reducers/hooks";
import {
  getSubscriptionTransferByIdApi,
  getSubscriptionTransferListApi,
  postSubscriptionTransferApi,
  putSubscriptionTransferApi,
} from "app/services/subscriptionTransfer";
import {
  PaginationInterfaceLF,
  SubscriptionTransferByIdAPIResponse,
  SubscriptionTransferList,
  SubscriptionTransferPayload,
} from "app/types";

interface InitialState {
  subscriptionTransferList: SubscriptionTransferList[];
  subscriptionTransferById: SubscriptionTransferByIdAPIResponse | null;
  subscriptionTransferListPagination: PaginationInterfaceLF;
  subscriptionTransferListRowsPerPAge: number;
  isSubscriptionTransferListLoading: boolean;
  subscriptionTransferDetailsLoading: boolean;
}

const initialState: InitialState = {
  subscriptionTransferList: [],
  subscriptionTransferById: null,
  subscriptionTransferListRowsPerPAge: 20,
  subscriptionTransferListPagination: null,
  isSubscriptionTransferListLoading: false,
  subscriptionTransferDetailsLoading: false,
};

export const subscriptionTransfer = createSlice({
  name: "subscriptionTransfer",
  initialState,
  reducers: {
    setSubscriptionTransferList: (
      state,
      action: PayloadAction<{
        list: SubscriptionTransferList[];
        pagination: PaginationInterfaceLF;
      }>
    ) => {
      state.subscriptionTransferList = action.payload.list;
      state.subscriptionTransferListPagination = action.payload.pagination;
    },
    setSubscriptionTransferByIdList: (
      state,
      action: PayloadAction<SubscriptionTransferByIdAPIResponse>
    ) => {
      state.subscriptionTransferById = action.payload;
    },

    setSubscriptionTransferListLoading: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isSubscriptionTransferListLoading = action.payload;
    },
    setSubscriptionTransferListRowPerPage: (
      state,
      action: PayloadAction<number>
    ) => {
      state.subscriptionTransferListRowsPerPAge = action.payload;
    },
    setSubscriptionTransferDetailsLoading: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.subscriptionTransferDetailsLoading = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setSubscriptionTransferList,
  setSubscriptionTransferListRowPerPage,
  setSubscriptionTransferListLoading,
  setSubscriptionTransferByIdList,
  setSubscriptionTransferDetailsLoading,
} = subscriptionTransfer.actions;

export default subscriptionTransfer.reducer;

export const getsubscriptionTransferListAction = (
  { continutationToken } = {
    continutationToken: null,
  },
  storeId: number
) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const { subscriptionTransferListRowsPerPAge } =
        getState().subscriptionTransfer;
      let query = "";
      query =
        "?" +
        createQuery({
          filter: {
            limit: subscriptionTransferListRowsPerPAge,
            continutationToken,
          },
        });
      dispatch(setSubscriptionTransferListLoading(true));
      const { data } = await getSubscriptionTransferListApi(storeId, query);
      dispatch(setSubscriptionTransferListLoading(false));
      const pagination = data.pagination;
      dispatch(
        setSubscriptionTransferList({
          list: data.data,
          pagination,
        })
      );
    } catch (error) {
      const message = errorHandler(error);
      dispatch(setSubscriptionTransferListLoading(false));
      dispatch(alertMessage(message, "error"));
    }
  };
};

export const getsubscriptionTransferByIdListAction = (
  storeId: number,
  id: number
) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(setSubscriptionTransferDetailsLoading(true));
      const { data } = await getSubscriptionTransferByIdApi(storeId, id);
      dispatch(setSubscriptionTransferDetailsLoading(false));

      // Dispatch the single response object, not an array
      dispatch(
        setSubscriptionTransferByIdList(data) // Directly dispatch the single data object
      );
    } catch (error) {
      const message = errorHandler(error);
      dispatch(setSubscriptionTransferListLoading(false));
      dispatch(alertMessage(message, "error"));
    }
  };
};

export const createSubscriptionTransferAction =
  (storeId: number, payload: SubscriptionTransferPayload) =>
  async (dispatch: AppDispatch) => {
    try {
      const { data } = await postSubscriptionTransferApi(storeId, payload);
      if (data.data) {
        dispatch(alertMessage("Created successfully", "success"));
        dispatch(
          getsubscriptionTransferListAction(
            { continutationToken: null },
            storeId
          )
        );
      }
    } catch (error) {
      dispatch(alertMessage("Something went wrong", "error"));
    }
  };

export const updateSubscriptionTransferAction =
  (storeId: number, payload: SubscriptionTransferPayload, id: number) =>
  async (dispatch: AppDispatch) => {
    try {
      const { data } = await putSubscriptionTransferApi(storeId, payload, id);
      if (data.data) {
        dispatch(alertMessage("Updated successfully", "success"));
        dispatch(
          getsubscriptionTransferListAction(
            { continutationToken: null },
            storeId
          )
        );
      }
    } catch (error) {
      dispatch(alertMessage("Something went wrong", "error"));
    }
  };
