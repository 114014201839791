import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { AppDispatch } from "../store";
import { alertMessage } from "../actions/common";
import { errorHandler } from "app/shared/Service/errorHandler";
import { createQuery } from "app/utils/constants/common";
import { RootState } from "../reducers/hooks";
import { PaginationInterfaceLF } from "app/types";
import {
  getBundleByStoreAndPackageId,
  getBundleUnitsByStoreAndPackageId,
  getPackagesListApi,
  postBundlesApi,
  postBundleUnitsApi,
  postPackagesApi,
  putBundleApi,
  putBundleUnitApi,
  putPackagesApi,
} from "app/services/packages";
import {
  CreatePackagePayload,
  PackagesInterface,
  PostBundlePayload,
} from "app/types/package";
import {
  Bundle,
  BundleUnitByIdInterface,
  BundleUnitPayload,
  Currency,
  Portion,
} from "app/types/common";

interface PackagesFilters {
  storeId: number;
  store: string;
  type?: string;
  search?: string;
}
interface InitialState {
  packagesList: PackagesInterface[];
  packagesListPagination: PaginationInterfaceLF;
  packagesListRowsPerPAge: number;
  isPackagesListLoading: boolean;
  packagesDetailsLoading: boolean;
  filters: PackagesFilters | null;

  bundleList: Bundle[];
  bundleListPagination: PaginationInterfaceLF | null;
  bundleListRowsPerPage: number;
  isBundleListLoading: boolean;
  isSubmittingBundle: boolean;

  bundleUnits: BundleUnitByIdInterface[];
  bundleUnitsPagination: PaginationInterfaceLF | null;
  bundleUnitsRowsPerPage: number;
  isBundleUnitsLoading: boolean;
  isSubmittingBundleUnits: boolean;
  bundleUnitsIncluded: {
    currencies: Currency[];
    portions: Portion[];
  };
}

const initialState: InitialState = {
  packagesList: [],
  packagesListRowsPerPAge: 20,
  packagesListPagination: null,
  isPackagesListLoading: false,
  packagesDetailsLoading: false,
  filters: null,

  bundleList: [],
  bundleListPagination: null,
  bundleListRowsPerPage: 20,
  isBundleListLoading: false,
  isSubmittingBundle: false,

  bundleUnits: [],
  bundleUnitsIncluded: null,
  bundleUnitsPagination: null,
  bundleUnitsRowsPerPage: 20,
  isBundleUnitsLoading: false,
  isSubmittingBundleUnits: false,
};

export const packages = createSlice({
  name: "packages",
  initialState,
  reducers: {
    setPackagesList: (
      state,
      action: PayloadAction<{
        list: PackagesInterface[];
        pagination: PaginationInterfaceLF;
      }>
    ) => {
      state.packagesList = action.payload.list;
      state.packagesListPagination = action.payload.pagination;
    },

    setPackagesListLoading: (state, action: PayloadAction<boolean>) => {
      state.isPackagesListLoading = action.payload;
    },
    setPackagesListRowPerPage: (state, action: PayloadAction<number>) => {
      state.packagesListRowsPerPAge = action.payload;
    },
    setPackagesDetailsLoading: (state, action: PayloadAction<boolean>) => {
      state.packagesDetailsLoading = action.payload;
    },
    setPackagesFilters: (
      state,
      action: PayloadAction<PackagesFilters | null>
    ) => {
      state.filters = action.payload;
    },

    setBundleList: (
      state,
      action: PayloadAction<{
        list: Bundle[];
        pagination: PaginationInterfaceLF;
      }>
    ) => {
      state.bundleList = action.payload.list;
      state.bundleListPagination = action.payload.pagination;
    },
    setBundleListLoading: (state, action: PayloadAction<boolean>) => {
      state.isBundleListLoading = action.payload;
    },
    setBundleListRowPerPage: (state, action: PayloadAction<number>) => {
      state.bundleListRowsPerPage = action.payload;
    },
    setSubmittingBundle: (state, action: PayloadAction<boolean>) => {
      state.isSubmittingBundle = action.payload;
    },

    setBundleUnits: (
      state,
      action: PayloadAction<{
        list: BundleUnitByIdInterface[];
        pagination: PaginationInterfaceLF;
        included: {
          currencies: Currency[];
          portions: Portion[];
        };
      }>
    ) => {
      state.bundleUnits = action.payload.list;
      state.bundleUnitsPagination = action.payload.pagination;
      state.bundleUnitsIncluded = action.payload.included;
    },

    setBundleUnitsLoading: (state, action: PayloadAction<boolean>) => {
      state.isBundleUnitsLoading = action.payload;
    },
    setBundleUnitsRowPerPage: (state, action: PayloadAction<number>) => {
      state.bundleUnitsRowsPerPage = action.payload;
    },
    setSubmittingBundleUnits: (state, action: PayloadAction<boolean>) => {
      state.isSubmittingBundleUnits = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setPackagesList,
  setPackagesListLoading,
  setPackagesListRowPerPage,
  setPackagesDetailsLoading,
  setPackagesFilters,

  setBundleList,
  setBundleListLoading,
  setBundleListRowPerPage,
  setSubmittingBundle,

  setBundleUnitsLoading,
  setBundleUnits,
  setBundleUnitsRowPerPage,
  setSubmittingBundleUnits,
} = packages.actions;

export default packages.reducer;

export const getPackagesListAction = (
  { continutationToken } = {
    continutationToken: null,
  },
  storeId: number
) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const { filters, packagesListRowsPerPAge } = getState().packages;
      let query = "";
      query =
        "?" +
        createQuery({
          filter: {
            ...(filters || {}),
            limit: packagesListRowsPerPAge,
            continutationToken,
          },
        });
      dispatch(setPackagesListLoading(true));
      const { data } = await getPackagesListApi(storeId, query);
      dispatch(setPackagesListLoading(false));
      const packages = data.data;
      const pagination = data.pagination;
      const groups = data.included?.groups || [];
      // convert groups to Map
      const groupsMap = new Map(groups.map((group) => [group.groupId, group]));
      packages.forEach((pkg) => {
        pkg.group = groupsMap.get(pkg.groupId);
      });
      dispatch(
        setPackagesList({
          list: packages,
          pagination,
        })
      );
    } catch (error) {
      const message = errorHandler(error);
      dispatch(setPackagesListLoading(false));
      dispatch(alertMessage(message, "error"));
    }
  };
};

type CreatePackageRequestParam = {
  storeId: number;
  payload: CreatePackagePayload;
};
export const createPackagesAction = ({
  storeId,
  payload,
}: CreatePackageRequestParam) => {
  return async (dispatch: AppDispatch) => {
    try {
      const { data } = await postPackagesApi(storeId, payload);
      if (data.data) {
        dispatch(alertMessage("Created successfully", "success"));
        dispatch(getPackagesListAction({ continutationToken: null }, storeId));
      }
      return data;
    } catch (error) {
      dispatch(alertMessage("Something went wrong", "error"));
    }
  };
};

type UpdatePackageRequestParam = {
  storeId: number;
  packageId: number;
  payload: CreatePackagePayload;
};

export const updatePackagesAction =
  ({ storeId, packageId, payload }: UpdatePackageRequestParam) =>
  async (dispatch: AppDispatch) => {
    try {
      dispatch(setPackagesDetailsLoading(true));
      const { data } = await putPackagesApi(storeId, packageId, payload);
      dispatch(setPackagesDetailsLoading(false));
      if (data.data) {
        dispatch(alertMessage("Updated successfully", "success"));
        dispatch(getPackagesListAction({ continutationToken: null }, storeId));
        return data;
      }
    } catch (error) {
      dispatch(setPackagesDetailsLoading(false));
      dispatch(alertMessage("Something went wrong", "error"));
    }
  };

type GetBundlesActionParams = {
  packageId: number;
  storeId: number;
  continutationToken?: string | null;
};
export const getBundlesAction =
  ({ packageId, storeId, continutationToken = null }: GetBundlesActionParams) =>
  async (
    dispatch: AppDispatch,
    getState: () => RootState
  ): Promise<Bundle[]> => {
    try {
      const { bundleListRowsPerPage } = getState().packages;
      let query = "";
      query =
        "?" +
        createQuery({
          filter: {
            limit: bundleListRowsPerPage,
            continutationToken,
          },
        });

      dispatch(setBundleListLoading(true));
      const { data } = await getBundleByStoreAndPackageId({
        storeId,
        packageId,
        query,
      });
      dispatch(setBundleListLoading(false));
      const bundles = data.data;
      const pagination = data.pagination;

      dispatch(
        setBundleList({
          list: bundles,
          pagination,
        })
      );

      return data.data;
    } catch (error) {
      dispatch(setBundleListLoading(false));
      const message = errorHandler(error);
      dispatch(alertMessage(message, "error"));
    }
  };

type PostBundleActionParams = {
  packageId: number;
  storeId: number;
  payload: PostBundlePayload;
};
export const postBundleAction =
  ({ packageId, storeId, payload }: PostBundleActionParams) =>
  async (dispatch: AppDispatch) => {
    try {
      dispatch(setSubmittingBundle(true));
      const { data } = await postBundlesApi({ packageId, storeId, payload });
      dispatch(setSubmittingBundle(false));
      if (data?.data) {
        dispatch(alertMessage("Created successfully", "success"));
        return data.data;
      }
    } catch (error) {
      const message = errorHandler(error);
      dispatch(alertMessage(message, "error"));
      dispatch(setSubmittingBundle(false));
    }
  };

export const putBundleAction =
  ({
    packageId,
    bundleId,
    storeId,
    payload,
  }: PostBundleActionParams & { bundleId: number }) =>
  async (dispatch: AppDispatch) => {
    try {
      dispatch(setSubmittingBundle(true));
      const { data } = await putBundleApi({
        packageId,
        bundleId,
        storeId,
        payload,
      });
      dispatch(setSubmittingBundle(false));
      if (data?.data) {
        dispatch(alertMessage("Updated successfully", "success"));
        return data.data;
      }
    } catch (error) {
      const message = errorHandler(error);
      dispatch(alertMessage(message, "error"));
      dispatch(setSubmittingBundle(false));
    }
  };

export const getBundleUnitsAction = ({
  storeId,
  packageId,
  bundleId,
  continutationToken = null,
}: {
  storeId: number;
  packageId: number;
  bundleId: number;
  continutationToken?: string | null;
}) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const { bundleUnitsRowsPerPage } = getState().packages;
      let query = "";
      query =
        "?" +
        createQuery({
          filter: {
            limit: bundleUnitsRowsPerPage,
            continutationToken,
          },
        });
      dispatch(setBundleUnitsLoading(true));
      const { data } = await getBundleUnitsByStoreAndPackageId({
        storeId,
        packageId,
        bundleId,
        query: query,
      });
      dispatch(setBundleUnitsLoading(false));
      const pagination = data.pagination;
      const bundleUnits = data.data;
      const included = data.included;

      dispatch(
        setBundleUnits({
          list: bundleUnits,
          pagination,
          included,
        })
      );
      return data.data;
    } catch (error) {
      const message = errorHandler(error);
      dispatch(alertMessage(message, "error"));
      dispatch(setBundleUnitsLoading(false));
    }
  };
};

export const postBundleUnitAction = ({
  storeId,
  packageId,
  bundleId,
  payload,
}: {
  storeId: number;
  packageId: number;
  bundleId: number;
  payload: BundleUnitPayload;
}) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(setSubmittingBundleUnits(true));
      const { data } = await postBundleUnitsApi({
        storeId,
        packageId,
        bundleId,
        payload,
      });
      dispatch(setSubmittingBundleUnits(false));
      if (data?.data) {
        dispatch(alertMessage("Created successfully", "success"));
        return data.data;
      }
    } catch (error) {
      const message = errorHandler(error);
      dispatch(alertMessage(message, "error"));
      dispatch(setSubmittingBundleUnits(false));
    }
  };
};

export const putBundleUnitAction = ({
  storeId,
  packageId,
  bundleId,
  unitId,
  payload,
}: {
  storeId: number;
  packageId: number;
  bundleId: number;
  unitId: number;
  payload: BundleUnitPayload;
}) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(setSubmittingBundleUnits(true));
      const { data } = await putBundleUnitApi({
        storeId,
        packageId,
        bundleId,
        unitId,
        payload,
      });
      dispatch(setSubmittingBundleUnits(false));
      if (data?.data) {
        dispatch(alertMessage("Updated successfully", "success"));
        return data.data;
      }
    } catch (error) {
      const message = errorHandler(error);
      dispatch(alertMessage(message, "error"));
      dispatch(setSubmittingBundleUnits(false));
    }
  };
};
