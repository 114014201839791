import axios from "./config";
import { AxiosResponse } from "axios";

import {
  DefaultDiscountPayload,
  GetDefaultDiscountApiResponse,
  PostDefaultDiscountApiResponse,
  PostPromotionApiResponse,
  PromotionLogsApiResponse,
  PromotionsByIdAPIResponse,
  PromotionsListAPIResponse,
  PromotionsPayload,
} from "app/types/promotions";

type GetPromptionByIdApiRequest = {
  storeId: number;
  promotionId: number;
};

type PostPromotionsApiRequest = {
  payload: PromotionsPayload;
  storeId: number;
};

type PutPromotionsApiRequest = {
  payload: PromotionsPayload;
  storeId: number;
  promotionId: number;
};

type GetDefaultDiscountRequestParams = {
  storeId: number;
  promotionId: number;
  query: string;
};

type PutDefaultDiscountRequestParams = {
  storeId: number;
  promotionId: number;
  defaultDiscountId: number;
  payload: DefaultDiscountPayload;
};

type DeleteDefaultDiscountRequestParams = {
  storeId: number;
  promotionId: number;
  defaultDiscountId: number;
};

export const getPromotionsListApi = (
  storeId: number,
  query = ""
): Promise<AxiosResponse<PromotionsListAPIResponse>> =>
  axios.get<PromotionsListAPIResponse>(`/stores/${storeId}/promotions${query}`);

export const getPromotionsByIdApi = ({
  storeId,
  promotionId,
}: GetPromptionByIdApiRequest): Promise<
  AxiosResponse<PromotionsByIdAPIResponse>
> =>
  axios.get<PromotionsByIdAPIResponse>(
    `/stores/${storeId}/promotions/${promotionId}`
  );

export const postPromotionsApi = ({
  payload,
  storeId,
}: PostPromotionsApiRequest): Promise<
  AxiosResponse<PostPromotionApiResponse>
> =>
  axios.post<PostPromotionApiResponse>(
    `/stores/${storeId}/promotions`,
    payload
  );

export const putPromotionsApi = ({
  payload,
  storeId,
  promotionId,
}: PutPromotionsApiRequest): Promise<AxiosResponse<PostPromotionApiResponse>> =>
  axios.put<PostPromotionApiResponse>(
    `/stores/${storeId}/promotions/${promotionId}`,
    payload
  );

// Default discount CRUD operations

export const getDefaultDiscountsApi = ({
  storeId,
  promotionId,
  query,
}: GetDefaultDiscountRequestParams): Promise<
  AxiosResponse<GetDefaultDiscountApiResponse>
> =>
  axios.get<GetDefaultDiscountApiResponse>(
    `/stores/${storeId}/promotions/${promotionId}/default-discounts${query}`
  );

type PostDefaultDiscountRequestParams = {
  storeId: number;
  promotionId: number;
  payload: DefaultDiscountPayload;
};

export const postDefaultDiscountApi = ({
  storeId,
  promotionId,
  payload,
}: PostDefaultDiscountRequestParams): Promise<
  AxiosResponse<PostDefaultDiscountApiResponse>
> =>
  axios.post<PostDefaultDiscountApiResponse>(
    `/stores/${storeId}/promotions/${promotionId}/default-discounts`,
    payload
  );

export const putDefaultDiscountApi = ({
  storeId,
  promotionId,
  defaultDiscountId,
  payload,
}: PutDefaultDiscountRequestParams): Promise<
  AxiosResponse<PostDefaultDiscountApiResponse>
> =>
  axios.put<PostDefaultDiscountApiResponse>(
    `/stores/${storeId}/promotions/${promotionId}/default-discounts/${defaultDiscountId}`,
    payload
  );

export const deleteDefaultDiscountApi = ({
  storeId,
  promotionId,
  defaultDiscountId,
}: DeleteDefaultDiscountRequestParams): Promise<AxiosResponse<void>> =>
  axios.delete<void>(
    `/stores/${storeId}/promotions/${promotionId}/default-discounts/${defaultDiscountId}`
  );

type GetPromotionLogsApiRequest = {
  storeId: number;
  promotionId: number;
  query: string;
};
export const getPromotionLogsApi = ({
  storeId,
  promotionId,
  query,
}: GetPromotionLogsApiRequest): Promise<
  AxiosResponse<PromotionLogsApiResponse>
> =>
  axios.get<PromotionLogsApiResponse>(
    `/stores/${storeId}/promotions/${promotionId}/logs${query}`
  );
