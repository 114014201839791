import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { AppDispatch } from "../store";
import { alertMessage } from "../actions/common";
import { errorHandler } from "app/shared/Service/errorHandler";
import { CatalogPackages } from "app/types/catalogs";
import {
  CreateCatalogPackagesAPIPayload,
  createCatalogPackagesAPI,
  getCatalogPackagesAPI,
} from "app/services/catalogs";

interface InitialState {
  catalogPackages: CatalogPackages;
  isCatalogPackagesLoading: boolean;
}

const initialState: InitialState = {
  catalogPackages: null,
  isCatalogPackagesLoading: false,
};

export const catalogsSlice = createSlice({
  name: "catalogsSlice",
  initialState,
  reducers: {
    setCatalogPackages: (state, action: PayloadAction<CatalogPackages>) => {
      state.catalogPackages = action.payload;
    },
    setCatalogPackagesLoading: (state, action: PayloadAction<boolean>) => {
      state.isCatalogPackagesLoading = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setCatalogPackages, setCatalogPackagesLoading } =
  catalogsSlice.actions;

export default catalogsSlice.reducer;

export const getCatalogPackagesAction = (catalogId: number) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(setCatalogPackagesLoading(true));
      const { data } = await getCatalogPackagesAPI(catalogId);
      dispatch(setCatalogPackagesLoading(false));

      const dataObject = data.data;
      const packageIds = dataObject.packageIds;
      const includedPackages = data.included.packages;

      // Update the CatalogPackages to add the package objects to it
      const updatedCatalogPackages = packageIds?.map((item) => {
        return includedPackages?.find((p) => p.pacakgeId === item);
      });

      dispatch(
        setCatalogPackages({
          availableFrom: dataObject.availableFrom,
          availableUntil: dataObject.availableUntil,
          packages: updatedCatalogPackages,
        })
      );
    } catch (error) {
      dispatch(setCatalogPackagesLoading(false));
      dispatch(setCatalogPackages(null));
      const message = errorHandler(error);
      dispatch(alertMessage(message, "error"));
    }
  };
};

type CreateCatalogPackagesActionPayload = {
  catalogId: number;
  payload: CreateCatalogPackagesAPIPayload;
  successCallback: () => void;
};
export const createCatalogPackagesAction =
  ({
    catalogId,
    payload,
    successCallback,
  }: CreateCatalogPackagesActionPayload) =>
  async (dispatch: AppDispatch) => {
    // Create/Update catalog packages
    try {
      await createCatalogPackagesAPI(catalogId, payload);

      // We refresh the CatalogPackages data
      dispatch(getCatalogPackagesAction(catalogId));
      successCallback();
    } catch (error) {
      const message = errorHandler(error);
      dispatch(alertMessage(message, "error"));
    }
  };
