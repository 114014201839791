import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PaginationInterfaceLF } from "app/types";
import {
  Group,
  GroupPayload,
  LocalizedGroup,
  LocalizedGroupPayload,
} from "app/types/package";
import { AppDispatch } from "../store";
import { RootState } from "../reducers/hooks";
import { createQuery, getActiveConfig } from "app/utils/constants/common";
import {
  getGroupListsApi,
  getLocalizedGroupsApi,
  postGroupApi,
  postLocalizedGroupApi,
  putGroupApi,
} from "app/services/groups";
import { errorHandler } from "app/shared/Service/errorHandler";
import { alertMessage } from "../actions/common";

type GroupsFilters = {
  storeId: number;
  store: string;
};
interface InitialState {
  groupsList: Group[];
  groupsPagination: PaginationInterfaceLF | null;
  groupsListRowPerPage: number;
  filters: GroupsFilters;
  isGroupsListLoading: boolean;
  isSubmitting: boolean;

  localizedGroups: LocalizedGroup[];
  localizedGroupsPagination: PaginationInterfaceLF | null;
  localizedGroupsListRowPerPage: number;
  isLocalizedGroupsListLoading: boolean;
  isLocalizedGroupsSubmitting: boolean;
}

const initialState: InitialState = {
  groupsList: [],
  groupsPagination: null,
  groupsListRowPerPage: 20,
  filters: null,
  isGroupsListLoading: false,
  isSubmitting: false,

  localizedGroups: [],
  localizedGroupsPagination: null,
  localizedGroupsListRowPerPage: 20,
  isLocalizedGroupsListLoading: false,
  isLocalizedGroupsSubmitting: false,
};

export const groupsSlice = createSlice({
  name: "groups",
  initialState,
  reducers: {
    setGroupsList: (
      state,
      action: PayloadAction<{
        groups: Group[];
        pagination: PaginationInterfaceLF;
      }>
    ) => {
      state.groupsList = action.payload.groups;
      state.groupsPagination = action.payload.pagination;
    },
    setGroupsLoading: (state, action: PayloadAction<boolean>) => {
      state.isGroupsListLoading = action.payload;
    },
    setGroupsListRowPerPage: (state, action: PayloadAction<number>) => {
      state.groupsListRowPerPage = action.payload;
    },
    setGroupsFilters: (state, action: PayloadAction<GroupsFilters | null>) => {
      state.filters = action.payload;
    },
    setGroupSubmitting: (state, action: PayloadAction<boolean>) => {
      state.isSubmitting = action.payload;
    },

    setLocalizedGroupsList: (
      state,
      action: PayloadAction<{
        groups: LocalizedGroup[];
        pagination: PaginationInterfaceLF;
      }>
    ) => {
      state.localizedGroups = action.payload.groups;
      state.localizedGroupsPagination = action.payload.pagination;
    },
    setLocalizedGroupsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLocalizedGroupsListLoading = action.payload;
    },
    setLocalizedGroupsListRowPerPage: (
      state,
      action: PayloadAction<number>
    ) => {
      state.localizedGroupsListRowPerPage = action.payload;
    },
    setLocalizedGroupsSubmitting: (state, action: PayloadAction<boolean>) => {
      state.isLocalizedGroupsSubmitting = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setGroupsList,
  setGroupsLoading,
  setGroupsListRowPerPage,
  setGroupsFilters,
  setGroupSubmitting,
  setLocalizedGroupsList,
  setLocalizedGroupsLoading,
  setLocalizedGroupsListRowPerPage,
  setLocalizedGroupsSubmitting,
} = groupsSlice.actions;
export default groupsSlice.reducer;

type GetGroupsListActionParams = {
  continutationToken?: string | null;
  storeId: number;
};

export const getGroupsListAction = ({
  continutationToken = null,
  storeId,
}: GetGroupsListActionParams) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const { filters, groupsListRowPerPage } = getState().groups;
      let query = "";
      query =
        "?" +
        createQuery({
          filter: {
            ...filters,
            limit: groupsListRowPerPage,
            continutationToken,
          },
        });
      dispatch(setGroupsLoading(true));
      const { data } = await getGroupListsApi(storeId, query);
      dispatch(setGroupsLoading(false));

      const pagination = data.pagination;
      const groups = data.data;

      dispatch(setGroupsList({ groups, pagination }));
    } catch (error) {
      dispatch(setGroupsLoading(false));
      const message = errorHandler(error);
      dispatch(alertMessage(message, "error"));
    }
  };
};

export const getGroupsAction = ({
  storeId,
  search = "",
}: {
  storeId: number;
  search?: string;
}) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      let query = "";
      query =
        "?" +
        createQuery({
          filter: {
            limit: 1000,
            search: search,
          },
        });

      const { data } = await getGroupListsApi(storeId, query);
      const groups = data.data;

      return groups;
    } catch (error) {
      const message = errorHandler(error);
      dispatch(alertMessage(message, "error"));
    }
  };
};

export const postGroupAction = (payload: GroupPayload) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const { filters } = getState().groups;
      const { defaultStoreId } = getActiveConfig();
      dispatch(setGroupSubmitting(true));
      const { data } = await postGroupApi({
        payload,
        storeId: filters?.storeId || defaultStoreId,
      });
      dispatch(setGroupSubmitting(false));
      dispatch(alertMessage("Group added successfully", "success"));
      return data;
    } catch (error) {
      dispatch(setGroupSubmitting(false));
      const message = errorHandler(error);
      dispatch(alertMessage(message, "error"));
    }
  };
};

export const putGroupAction = (groupId: number, payload: GroupPayload) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const { filters } = getState().groups;
      const { defaultStoreId } = getActiveConfig();
      dispatch(setGroupSubmitting(true));
      const { data } = await putGroupApi({
        payload,
        storeId: filters?.storeId || defaultStoreId,
        groupId,
      });
      dispatch(setGroupSubmitting(false));
      dispatch(alertMessage("Group updated successfully", "success"));
      return data;
    } catch (error) {
      dispatch(setGroupSubmitting(false));
      const message = errorHandler(error);
      dispatch(alertMessage(message, "error"));
    }
  };
};

type GetLocalizedGroupsActionParams = {
  storeId: number;
  groupId: number;
  continutationToken?: string | null;
};

export const getLocalizedGroupsAction = ({
  storeId,
  groupId,
  continutationToken,
}: GetLocalizedGroupsActionParams) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const { localizedGroupsListRowPerPage } = getState().groups;
      let query = "";
      query =
        "?" +
        createQuery({
          filter: {
            limit: localizedGroupsListRowPerPage,
            continutationToken,
          },
        });

      dispatch(setLocalizedGroupsLoading(true));
      const { data } = await getLocalizedGroupsApi({ storeId, groupId, query });
      dispatch(setLocalizedGroupsLoading(false));

      const pagination = data.pagination;
      const groups = data.data;

      dispatch(setLocalizedGroupsList({ groups, pagination }));
    } catch (error) {
      dispatch(setLocalizedGroupsLoading(false));
      const message = errorHandler(error);
      dispatch(alertMessage(message, "error"));
    }
  };
};

type PostLocalizedGroupActionParams = {
  storeId: number;
  groupId: number;
  payload: LocalizedGroupPayload;
};
export const postLocalizedGroupAction = ({
  storeId,
  groupId,
  payload,
}: PostLocalizedGroupActionParams) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(setLocalizedGroupsSubmitting(true));
      const { data } = await postLocalizedGroupApi({
        storeId,
        groupId,
        payload,
      });
      dispatch(setLocalizedGroupsSubmitting(false));
      dispatch(alertMessage("Localized group added successfully", "success"));
      return data;
    } catch (error) {
      dispatch(setLocalizedGroupsSubmitting(false));
      const message = errorHandler(error);
      dispatch(alertMessage(message, "error"));
    }
  };
};
