import { CatalogPackagesApiResponse } from "app/types/catalogs";
import axios from "./config";
import { createQuery } from "app/utils/constants/common";
import { AxiosResponse } from "axios";

export const getCatalogsAPI = (payload?: any) => {
  let query = "";

  if (payload) {
    query = "?" + createQuery({ filter: payload });
  }

  return axios.get(`/catalogs${query}`);
};

export const createCatalogAPI = (payload: object) =>
  axios.post(`/catalogs`, payload);

export const updateCatalogAPI = (catalogId: number, payload: object) =>
  axios.put(`/catalogs/${catalogId}`, payload);

export const deleteCatalogAPI = (catalogId: number) =>
  axios.delete(`/catalogs/${catalogId}`);

export const detailCatalogMenuAPI = (catalogId: number) =>
  axios.get(`/catalogs/${catalogId}`);

export const createCatalogMenuAPI = (catalogId: number, payload: object) =>
  axios.post(`/catalogs/${catalogId}/Menus`, payload);

export const updateCatalogMenuAPI = (
  catalogId: number,
  payload: object,
  menuId: number
) => axios.put(`/catalogs/${catalogId}/menus/${menuId}`, payload);

export const deleteMenuAPI = (menuId: number, catalogId: number) =>
  axios.delete(`/catalogs/${catalogId}/menus/${menuId}`);

export const getMenuByIdAPI = (catalogId: number, menuId: number) =>
  axios.get(`/catalogs/${catalogId}/menus/${menuId}`);

export const addMealIntoMenuAPI = (
  catalogId: number,
  menuId: number,
  payload: any
) => axios.post(`/catalogs/${catalogId}/menus/${menuId}/meals`, payload);

export const deleteMealIntoMenuAPI = (
  catalogId: number,
  menuId: number,
  mealName: string,
  classification: string,
  dishId: number
) =>
  axios.delete(
    `/catalogs/${catalogId}/menus/${menuId}/dishes/${dishId}/meals/${mealName}?classification=${classification}`
  );

export const getCatalogPackagesAPI = (
  catalogId: number
): Promise<AxiosResponse<CatalogPackagesApiResponse>> =>
  axios.get<CatalogPackagesApiResponse>(`/catalogs/${catalogId}/packages`);

export type CreateCatalogPackagesAPIPayload = {
  availableFrom: string;
  availableUntil: string;
  packageIds: number[];
};

export const createCatalogPackagesAPI = (
  catalogId: number,
  payload: CreateCatalogPackagesAPIPayload
) => axios.post(`/catalogs/${catalogId}/packages`, payload);
