import { SubscriptionTransferPayload } from "app/types";
import axios from "./config";

export const getSubscriptionTransferListApi = (
  storeId: number | string,
  query: string
) => axios.get(`/stores/${storeId}/package-transfers${query}`);

export const postSubscriptionTransferApi = (
  storeId: number,
  payload: SubscriptionTransferPayload
) => {
  return axios.post(`/stores/${storeId}/package-transfers`, payload);
};

export const putSubscriptionTransferApi = (
  storeId: number,
  payload: SubscriptionTransferPayload,
  packageTransferId: number
) => {
  return axios.put(
    `/stores/${storeId}/package-transfers/${packageTransferId}`,
    payload
  );
};

export const getSubscriptionTransferByIdApi = (
  storeId: number | string,
  packageTransferId: number
) => axios.get(`/stores/${storeId}/package-transfers/${packageTransferId}`);
