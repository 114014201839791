import { Privileges } from "app/utils/constants/common";

// TODO: To be named correctly based on the BE Roles
const Privileges1 = [
  Privileges.customerServiceAgent,
  Privileges.customerServiceSupervisor,
  Privileges.cloudKitchenManager,
  Privileges.centralKitchenStaff,
  Privileges.cloudKitchenStaff,
  Privileges.admin,
  Privileges.superAdmin,
  Privileges.support,
];

const Privileges2 = [
  Privileges.menuDeveloper,
  Privileges.admin,
  Privileges.superAdmin,
  Privileges.support,
];

const Privileges3 = [
  Privileges.customerServiceAgent,
  Privileges.customerServiceSupervisor,
  Privileges.dietitian,
  Privileges.admin,
  Privileges.superAdmin,
  Privileges.support,
];

const Privileges4 = [
  Privileges.centralKitchenManager,
  Privileges.admin,
  Privileges.superAdmin,
  Privileges.support,
];

const Privileges5 = [
  Privileges.admin,
  Privileges.superAdmin,
  Privileges.support,
  Privileges.logisticManager,
];

const Privileges6 = [
  Privileges.cloudKitchenManager,
  Privileges.centralKitchenStaff,
  Privileges.admin,
  Privileges.superAdmin,
  Privileges.support,
];

const SuperPowerMode = [
  Privileges.superAdmin,
  Privileges.support,
  Privileges.admin,
];

const menus = [
  {
    uri: "/dashboards/orders",
    label: "common.orders",
    type: "nav-item",
    privilege: Privileges1,
  },
  {
    uri: "/dashboards/menus/catalogs",
    label: "pages.meal.title",
    type: "nav-item",
    privilege: Privileges2,
  },
  {
    uri: "/dashboards/subscriptions",
    label: "pages.subscription.title",
    type: "nav-item",
    privilege: Privileges3,
  },
  {
    uri: "/dashboards/clients",
    label: "common.clients",
    type: "nav-item",
    privilege: Privileges3,
  },
  {
    uri: "/dashboards/productions",
    label: "pages.production.title",
    type: "nav-item",
    privilege: Privileges4,
  },
  {
    uri: "/dashboards/delivery-orders",
    label: "common.deliveryOrder",
    type: "nav-item",
    privilege: Privileges5,
  },

  {
    uri: "/dashboards/products",
    label: "common.products",
    type: "nav-item",
    privilege: Privileges6,
  },
  {
    uri: "/dashboards/stocks",
    label: "common.stocks",
    type: "nav-item",
    privilege: Privileges6,
  },
  {
    uri: "/dashboards/deliveryAreas",
    label: "common.configuration",
    type: "nav-item",
    privilege: Privileges6,
  },
  {
    uri: "/dashboards/distribution-tasks",
    label: "Delivery",
    type: "collapsible",
    children: [
      {
        uri: "/dashboards/distribution-tasks",
        label: "Delivery Tasks",
        type: "nav-item",
        privilege: Privileges5,
      },
      {
        uri: "/dashboards/manage-drivers",
        label: "Manage Drivers",
        type: "nav-item",
        privilege: Privileges5,
      },
      {
        uri: "/dashboards/distribution-deliveries",
        label: "Call Center",
        type: "nav-item",
        privilege: Privileges5,
      },
      {
        uri: "/dashboards/distribution-delivery-planning",
        label: "Delivery Planning",
        type: "nav-item",
        privilege: Privileges5,
      },
    ],
    privilege: Privileges5,
  },
  {
    uri: "/dashboards/visits",
    label: "common.visits",
    type: "nav-item",
    privilege: Privileges3,
  },
  {
    uri: "/dashboards/consultations",
    label: "pages.consultation.consultations",
    type: "nav-item",
    privilege: Privileges3,
  },
  {
    uri: "/dashboards/customerSupport",
    label: "common.customerSupport",
    type: "nav-item",
    privilege: Privileges3,
  },
  {
    uri: "/dashboards/saudi-analytics",
    label: "common.analytics",
    type: "nav-item",
    privilege: Privileges1,
  },
  {
    uri: "/dashboards/promotions",
    label: "common.promotions",
    type: "nav-item",
    privilege: Privileges3,
  },
  {
    uri: "/dashboards/manage-packages",
    label: "Manage Packages",
    type: "collapsible",
    privilege: Privileges3,
    children: [
      {
        uri: "/dashboards/packages/groups",
        label: "Groups",
        type: "nav-item",
        privilege: Privileges3,
      },
      {
        uri: "/dashboards/packages",
        label: "Packages",
        type: "nav-item",
        privilege: Privileges3,
      },
      {
        uri: "/dashboards/price-plans-management",
        label: "Price Plans",
        type: "nav-item",
        privilege: Privileges3,
      },
    ],
  },
  {
    uri: "/dashboards/subscription-transfer",
    label: "Subcsription Transfer",
    type: "nav-item",
    privilege: SuperPowerMode,
  },
];

export default menus;
