import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PaginationInterfaceLF } from "app/types";
import {
  DefaultDiscount,
  DefaultDiscountPayload,
  PromotionLogs,
  Promotions,
  PromotionsPayload,
} from "app/types/promotions";
import { AppDispatch } from "../store";
import { RootState } from "../reducers/hooks";
import { errorHandler } from "app/shared/Service/errorHandler";
import { alertMessage } from "../actions/common";
import { createQuery, getActiveConfig } from "app/utils/constants/common";
import {
  deleteDefaultDiscountApi,
  getDefaultDiscountsApi,
  getPromotionLogsApi,
  getPromotionsByIdApi,
  getPromotionsListApi,
  postDefaultDiscountApi,
  postPromotionsApi,
  putDefaultDiscountApi,
  putPromotionsApi,
} from "app/services/promotions";

interface PromotionsFilters {
  storeId: number;
  search: string;
}

interface InitialState {
  promotionsList: Promotions[] | [];
  promotionsPagination: PaginationInterfaceLF;
  promotionsListRowPerPage: number;
  filters: PromotionsFilters | null;
  isSubmitting: boolean;
  isPromotionListLoading: boolean;

  defaultDiscountList: DefaultDiscount[] | [];
  defaultDiscountPagination: PaginationInterfaceLF;
  defaultDiscountListRowPerPage: number;
  isDefaultDiscountLoading: boolean;
  isSubmittingDefaultDiscount: boolean;

  promotionLogs: PromotionLogs[];
  promotionLogsPagination: PaginationInterfaceLF;
  promotionLogsListRowPerPage: number;
  isPromotionLogsLoading: boolean;
}

const initialState: InitialState = {
  promotionsList: [],
  promotionsPagination: null,
  promotionsListRowPerPage: 20,
  filters: null,
  isSubmitting: false,
  isPromotionListLoading: false,
  defaultDiscountList: [],
  defaultDiscountPagination: null,
  defaultDiscountListRowPerPage: 20,
  isDefaultDiscountLoading: false,
  isSubmittingDefaultDiscount: false,
  promotionLogs: [],
  promotionLogsPagination: null,
  promotionLogsListRowPerPage: 20,
  isPromotionLogsLoading: false,
};

export const promotionsSlice = createSlice({
  name: "promotions",
  initialState,
  reducers: {
    setPromotionsList: (
      state,
      action: PayloadAction<{
        promotions: Promotions[];
        pagination: PaginationInterfaceLF;
      }>
    ) => {
      state.promotionsList = action.payload.promotions;
      state.promotionsPagination = action.payload.pagination;
    },
    setPromotionsListRowPerPage: (state, action: PayloadAction<number>) => {
      state.promotionsListRowPerPage = action.payload;
    },
    setIsSubmitting: (state, action: PayloadAction<boolean>) => {
      state.isSubmitting = action.payload;
    },
    updatePromotionById: (state, action: PayloadAction<Promotions>) => {
      const index = state.promotionsList.findIndex(
        (p: Promotions) => p.id === action.payload.id
      );
      state.promotionsList[index] = action.payload;
    },
    setDefaultDiscountList: (
      state,
      action: PayloadAction<{
        defaultDiscountList: DefaultDiscount[];
        pagination: PaginationInterfaceLF;
      }>
    ) => {
      state.defaultDiscountList = action.payload.defaultDiscountList;
      state.defaultDiscountPagination = action.payload.pagination;
    },
    setDefaultDiscountListRowPerPage: (
      state,
      action: PayloadAction<number>
    ) => {
      state.defaultDiscountListRowPerPage = action.payload;
    },
    setIsDefaultDiscountLoading: (state, action: PayloadAction<boolean>) => {
      state.isDefaultDiscountLoading = action.payload;
    },
    setPromotionFilters: (
      state,
      action: PayloadAction<PromotionsFilters | null>
    ) => {
      state.filters = action.payload;
    },
    setIsPromotionListLoading: (state, action: PayloadAction<boolean>) => {
      state.isPromotionListLoading = action.payload;
    },
    setIsSubmittingDefaultDiscount: (state, action: PayloadAction<boolean>) => {
      state.isSubmittingDefaultDiscount = action.payload;
    },
    setPromotionLogs: (
      state,
      action: PayloadAction<{
        promotionLogs: PromotionLogs[];
        pagination: PaginationInterfaceLF;
      }>
    ) => {
      state.promotionLogs = action.payload.promotionLogs;
      state.promotionLogsPagination = action.payload.pagination;
    },
    setPromotionLogsListRowPerPage: (state, action: PayloadAction<number>) => {
      state.promotionLogsListRowPerPage = action.payload;
    },
    setIsPromotionLogsLoading: (state, action: PayloadAction<boolean>) => {
      state.isPromotionLogsLoading = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setPromotionsList,
  setPromotionsListRowPerPage,
  setIsSubmitting,
  updatePromotionById,
  setDefaultDiscountList,
  setDefaultDiscountListRowPerPage,
  setIsDefaultDiscountLoading,
  setPromotionFilters,
  setIsPromotionListLoading,
  setIsSubmittingDefaultDiscount,
  setPromotionLogs,
  setPromotionLogsListRowPerPage,
  setIsPromotionLogsLoading,
} = promotionsSlice.actions;
export default promotionsSlice.reducer;

type GetPromotionsListAction = {
  continutationToken?: string;
  storeId: number;
};
export const getPromotionsListAction = ({
  continutationToken = null,
  storeId,
}: GetPromotionsListAction) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const { filters, promotionsListRowPerPage } = getState().promotions;
      let query = "";
      query =
        "?" +
        createQuery({
          filter: {
            ...filters,
            limit: promotionsListRowPerPage,
            continutationToken,
          },
        });

      dispatch(setIsPromotionListLoading(true));
      const { data } = await getPromotionsListApi(storeId, query);
      dispatch(setIsPromotionListLoading(false));

      const pagination = data.pagination;
      const promotions = data.data;

      dispatch(setPromotionsList({ promotions, pagination }));
    } catch (error) {
      dispatch(setIsPromotionListLoading(false));
      const message = errorHandler(error);
      dispatch(alertMessage(message, "error"));
    }
  };
};

export const postPromotionsAction = (payload: PromotionsPayload) => {
  return async (dispatch: AppDispatch) => {
    try {
      const { defaultStoreId } = getActiveConfig();
      dispatch(setIsSubmitting(true));
      const { data } = await postPromotionsApi({
        payload,
        storeId: defaultStoreId,
      });
      dispatch(setIsSubmitting(false));
      dispatch(alertMessage("Promotion added successfully", "success"));
      return data;
    } catch (error) {
      dispatch(setIsSubmitting(false));
      const message = errorHandler(error);
      dispatch(alertMessage(message, "error"));
    }
  };
};

export const putPromotionsAction = ({
  promotionId,
  payload,
}: {
  promotionId: number;
  payload: PromotionsPayload;
}) => {
  return async (dispatch: AppDispatch) => {
    try {
      const { defaultStoreId } = getActiveConfig();
      dispatch(setIsSubmitting(true));
      const { data } = await putPromotionsApi({
        payload,
        storeId: defaultStoreId,
        promotionId: promotionId,
      });
      dispatch(setIsSubmitting(false));
      dispatch(alertMessage("Promotion updated successfully", "success"));
      return data?.data;
    } catch (error) {
      dispatch(setIsSubmitting(false));
      const message = errorHandler(error);
      dispatch(alertMessage(message, "error"));
    }
  };
};

export const getPromotionByIdAction = (promotionId: number) => {
  return async (dispatch: AppDispatch) => {
    try {
      const { defaultStoreId } = getActiveConfig();
      dispatch(setIsSubmitting(true));
      const { data } = await getPromotionsByIdApi({
        storeId: defaultStoreId,
        promotionId,
      });
      dispatch(setIsSubmitting(false));
      return data;
    } catch (error) {
      dispatch(setIsSubmitting(false));
      const message = errorHandler(error);
      dispatch(alertMessage(message, "error"));
    }
  };
};

export const getDefaultDiscountListAction = ({
  promotionId,
  continutationToken = null,
}: {
  promotionId: number;
  continutationToken?: string;
}) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const { defaultStoreId } = getActiveConfig();
      const { defaultDiscountListRowPerPage } = getState().promotions;

      const query =
        "?" +
        createQuery({
          filter: {
            limit: defaultDiscountListRowPerPage,
            continutationToken,
          },
        });
      dispatch(setIsDefaultDiscountLoading(true));
      const { data } = await getDefaultDiscountsApi({
        storeId: defaultStoreId,
        promotionId,
        query,
      });
      dispatch(setIsDefaultDiscountLoading(false));

      const pagination = data.pagination;
      const defaultDiscounts = data.data;
      // const users = data.included?.users || [];

      dispatch(
        setDefaultDiscountList({
          defaultDiscountList: defaultDiscounts,
          pagination,
        })
      );
    } catch (error) {
      dispatch(setIsDefaultDiscountLoading(false));
      const message = errorHandler(error);
      dispatch(alertMessage(message, "error"));
    }
  };
};

type PostDefaultDiscountAction = {
  promotionId: number;
  payload: DefaultDiscountPayload;
};

export const postDefaultDiscountAction = ({
  promotionId,
  payload,
}: PostDefaultDiscountAction) => {
  return async (dispatch: AppDispatch) => {
    try {
      const { defaultStoreId } = getActiveConfig();
      dispatch(setIsSubmittingDefaultDiscount(true));
      const { data } = await postDefaultDiscountApi({
        payload,
        storeId: defaultStoreId,
        promotionId,
      });
      dispatch(setIsSubmittingDefaultDiscount(false));
      dispatch(alertMessage("Default discount added successfully", "success"));
      return data;
    } catch (error) {
      dispatch(setIsSubmittingDefaultDiscount(false));
      const message = errorHandler(error);
      dispatch(alertMessage(message, "error"));
    }
  };
};

export const putDefaultDiscountAction = ({
  promotionId,
  defaultDiscountId,
  payload,
}: {
  promotionId: number;
  defaultDiscountId: number;
  payload: DefaultDiscountPayload;
}) => {
  return async (dispatch: AppDispatch) => {
    try {
      const { defaultStoreId } = getActiveConfig();
      dispatch(setIsSubmittingDefaultDiscount(true));
      const { data } = await putDefaultDiscountApi({
        payload,
        storeId: defaultStoreId,
        promotionId,
        defaultDiscountId,
      });
      dispatch(setIsSubmittingDefaultDiscount(false));
      dispatch(
        alertMessage("Default discount updated successfully", "success")
      );
      return data;
    } catch (error) {
      dispatch(setIsSubmittingDefaultDiscount(false));
      const message = errorHandler(error);
      dispatch(alertMessage(message, "error"));
    }
  };
};

export const deleteDefaultDiscountAction = ({
  promotionId,
  defaultDiscountId,
}: {
  promotionId: number;
  defaultDiscountId: number;
}) => {
  return async (dispatch: AppDispatch) => {
    try {
      const { defaultStoreId } = getActiveConfig();
      const response = await deleteDefaultDiscountApi({
        storeId: defaultStoreId,
        promotionId,
        defaultDiscountId,
      });
      dispatch(
        alertMessage("Default discount deleted successfully", "success")
      );
      return response;
    } catch (error) {
      const message = errorHandler(error);
      dispatch(alertMessage(message, "error"));
    }
  };
};

export const getPromotionLogsAction = ({
  promotionId,
  continutationToken = null,
}: {
  promotionId: number;
  continutationToken?: string;
}) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const { defaultStoreId } = getActiveConfig();
      const { promotionLogsListRowPerPage } = getState().promotions;

      const query =
        "?" +
        createQuery({
          filter: {
            limit: promotionLogsListRowPerPage,
            continutationToken,
          },
        });
      dispatch(setIsPromotionLogsLoading(true));
      const { data } = await getPromotionLogsApi({
        storeId: defaultStoreId,
        promotionId,
        query,
      });
      dispatch(setIsPromotionLogsLoading(false));

      const pagination = data.pagination;
      const promotionLogs = data.data;
      const users = data.included?.users || [];

      // loop through promotionLogs and add user details
      promotionLogs.forEach((log: PromotionLogs) => {
        const user = users.find((u) => u.id === log.userId);
        log.user = user;
      });

      dispatch(
        setPromotionLogs({
          promotionLogs,
          pagination,
        })
      );
    } catch (error) {
      dispatch(setIsPromotionLogsLoading(false));
      const message = errorHandler(error);
      dispatch(alertMessage(message, "error"));
    }
  };
};
