export const SET_MEALS_DATA = "SET_MEALS_DATA";
export const SET_MEALS_LOADING = "SET_MEALS_LOADING";
export const SET_MEALS_FILTER = "SET_MEALS_FILTER";
export const CLEAR_MEALS_STORE = "CLEAR_MEALS_STORE";
export const SET_MEALS_TOKENS = "SET_MEALS_TOKENS";
export const SET_MEALS_CATEGORIES = "SET_MEALS_CATEGORIES";
export const SET_DISH_DETAIL = "SET_DISH_DETAIL";
export const SET_DISH_LOCALE_DETAIL = "SET_DISH_LOCALE_DETAIL";
export const SET_RAW_MEAL_DATA = "SET_RAW_MEAL_DATA";
export const SET_PORTIONS = "SET_PORTIONS";
export const SET_RECIPE_DETAIL = "SET_RECIPE_DETAIL";
export const SET_RECIPE_LOADER = "SET_RECIPE_LOADER";
export const SET_DISH_RECIPES = "SET_DISH_RECIPES";
export const SET_KITCHEN_SECTIONS = "SET_KITCHEN_SECTIONS";
export const SET_EDIT_RECIPE_DETAILS = "SET_EDIT_RECIPE_DETAILS";
export const SET_NEW_RECIPE_ITEMS = "SET_NEW_RECIPE_ITEMS";
export const SET_RECIPE_ITEMS = "SET_RECIPE_ITEMS";
export const SET_SUBRECIPES = "SET_SUBRECIPES";
export const SET_SELECTED_SUBRECIPE = "SET_SELECTED_SUBRECIPE";
export const SET_SELECTED_ITEM = "SET_SELECTED_ITEM";
export const ADD_ITEM_TO_ROWID = "ADD_ITEM_TO_ROWID";
export const EDITING_SUB_RECIPE = "EDITING_SUB_RECIPE";
export const SET_ACTIVE_RECIPE_GROUP = "SET_ACTIVE_RECIPE_GROUP";
export const SET_SELECTED_PORTION = "SET_SELECTED_PORTION";

export const BlockedMealSelectionStatus = [
  {
    id: "UnderMaintenance",
    label: "Under Maintenance",
    name: "Under Maintenance",
  },
  { id: "Done", label: "Done", name: "Done" },
  { id: "Cancelled", label: "Cancelled", name: "Cancelled" },
];
